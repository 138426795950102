import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
import ParallaxImage from "../components/parallaxImage.js"
import DemoButtonContainer from "../components/demo-button-container.js"

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import "./groupPage.scss";

const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
}

export default function moduleGroupPage({ data, location }) {
    let group = null;
    let queryData = '';

    if (data.gcms.moduleGroups[0]) {
        group = data.gcms.moduleGroups[0];
        queryData = 'customer-journey/' + group.slug;
    } else if (data.gcms.customerTypes[0]) {
        group = data.gcms.customerTypes[0];
        queryData = 'voor-wie/' + group.slug;
    }
    if (group) {
        let pageTitle = '';
        let pageDescription = '';
        let metaDescription = '';
        let modules = [];
        if (group) {
            if (group.modulesPageTitle) {
                pageTitle = group.modulesPageTitle;
            }
            if (group.modulesPageDescription && group.modulesPageDescription.html) {
                pageDescription = group.modulesPageDescription.html;
            }
            if (group.modulesPageMetaDescription) {
                metaDescription = group.modulesPageMetaDescription;
            }
            if (group.modules && group.modules.length > 0) {
                modules = group.modules;
            }
        }

        return (
            <Layout>
                <SEO title={pageTitle} description={metaDescription} urlParams={queryData} />
                <img className="print print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
                <div className="modules-container">
                    <h1>{pageTitle}</h1>
                    <div className="row">
                        <div className="col-sm">
                            <div className="html-text" dangerouslySetInnerHTML={{ __html: nl2br(pageDescription) }} />
                        </div>
                        <div className="col-sm-5 parallax-container">
                            <ParallaxImage slug={group.slug + '-group-page'} timeline={group.parallaxAnimationType} displayType={group.parallaxDisplayType} images={group} />
                        </div>
                    </div>

                    {modules.map(({ slug, ...module }) => {
                        return <div className="inline-html" key={slug}>
                            <h2 className="module-title">{module.descriptionTitle}</h2>
                            <div className="html-text" dangerouslySetInnerHTML={{ __html: nl2br(module.description.html) }} />
                            <Link className="module-link" key={slug} to={`/modules/${slug}`}>Meer over {module.moduleName}
                                <div className="icon baseline">
                                    <ArrowRightAltIcon />
                                </div>
                            </Link>
                        </div>
                    })}
                </div>
                <div className="modules-demo-container">
                    <DemoButtonContainer titles={data.gcms.titles} />
                </div>
            </Layout>
        )
    } else {
        return (
            <Layout>
                <p>Error finding data</p>
            </Layout>
        )
    }
}

export const pageQuery = graphql`
query ModuleGroupPageQuery($id: ID) {
    gcms {
        moduleGroups(where: { id: $id }) {
            modulesPageTitle
            modulesPageDescription { html }
            modulesPageMetaDescription
            parallaxDisplayType
            parallaxAnimationType
            parallaxImage1
            parallaxImage2
            parallaxImage3
            slug
            modules {
                moduleName
                descriptionTitle
                description { html }
                slug
            }
        }
        titles {
            indicator
            value
            richValue { html }
        }
        customerTypes(where: { id: $id }) {
            modulesPageTitle
            modulesPageDescription { html }
            modulesPageMetaDescription
            parallaxDisplayType
            parallaxAnimationType
            parallaxImage1
            parallaxImage2
            parallaxImage3
            slug
            modules {
                moduleName
                descriptionTitle
                description { html }
                slug
            }
        }
    }
}`;
